.p__top {
  top: -1.5rem !important;
}

.add__container {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
}

.add__container > svg {
  font-size: 30px;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 10px;
}

.add__container__modal {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  direction: rtl;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.add__container__modal__wrapper {
  width: 1000px;
  height: fit-content;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.add__container__modal__wrapper > h3 {
  display: block;
  width: 100%;
  height: 45px;
  font-family: k;
}

.add__container__modal__wrapper > section {
  display: flex;
  align-items: center;
  width: 100%;
}

.add__container__modal__wrapper__right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex-direction: column;
  gap: 1rem;
  border-left: 1px solid #3498db;
}

.add__container__modal__wrapper__right > input {
  border: none;
  outline: none;
  border-bottom: 1px solid #060606;
  font-family: k;
  font-size: 16px;
  width: 70%;
  margin-top: 1rem;
}

.add__container__modal__wrapper__right__divs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.add__container__modal__wrapper__right__divs > span:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 5px;
}

.add__container__modal__wrapper__right__divs > span:nth-child(1) > p {
  margin-top: 12px;
  font-family: k;
  font-size: 14px;
}

.add__container__modal__wrapper__right__divs > span:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.add__container__modal__wrapper__right__divs > span:nth-child(2) > div {
  position: relative;
  margin-top: 1rem;
}

.add__container__modal__wrapper__right__divs > span:nth-child(2) > div > p {
  font-family: k;
  font-size: 12px;
  color: #444;
  position: absolute;
}

.add__container__modal__wrapper__right__divs > span:nth-child(2) > div > input {
  font-family: k;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 1px solid #060606;
}

.add__container__modal__wrapper__right__divs > span:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.add__container__modal__wrapper__right__divs > span:nth-child(3) > div {
  position: relative;
  margin-top: 2.5rem;
}

.add__container__modal__wrapper__right__divs > span:nth-child(3) > div > p {
  font-family: k;
  font-size: 12px;
  color: #444;
  position: absolute;
}

.add__container__modal__wrapper__right__divs > span:nth-child(3) > div > input {
  font-family: k;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 1px solid #060606;
}

.add__container__modal__wrapper__left {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex-direction: column;
  padding-right: 3rem;
}

.add__container__modal__wrapper__left > span:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 5px;
}

.add__container__modal__wrapper__left > span:nth-child(1) > svg {
  font-size: 24px;
  color: #060606;
}

.add__container__modal__wrapper__left > span:nth-child(1) > p {
  font-family: k;
  font-size: 16px;
  margin-top: 14px;
}

.left__one {
  position: relative;
  margin-top: 1.5rem;
}

.left__one > p {
  position: absolute;
  font-family: k;
  font-size: 12px;
}

.left__one > input {
  outline: none;
  border: none;
  border-bottom: 1px solid #060606;
  font-family: k;
  font-size: 14px;
  width: 100%;
}

.left__two {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 3.5rem;
}

.left__two > span > p {
  position: absolute;
  font-family: k;
  font-size: 12px;
}

.left__two > span > input {
  outline: none;
  border: none;
  border-bottom: 1px solid #060606;
  font-family: k;
  font-size: 14px;
  width: 100%;
}

.add__container__modal__wrapper > span {
  display: flex;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 1rem;
  justify-content: end;
  align-items: center;
  gap: 1rem;
}

.add__container__modal__wrapper > span > p {
  color: #3498db;
  font-family: k;
  font-size: 18px;
  cursor: pointer;
  margin-top: 14px;
  transition: 0.4s;
}

.add__container__modal__wrapper > span > p:hover {
  transform: scale(1.1);
}

.add__container__modal__wrapper > span > button {
  border: 1px solid #3498db;
  color: #3498db;
  font-family: k;
  font-size: 16px;
  outline: none;
  padding: 8px;
  background-color: transparent;
  border-radius: 5px;
  transition: 0.4s;
}

.add__container__modal__wrapper > span > button:hover {
  transform: scale(1.1);
  background-color: #3498db;
  color: #fff;
}

@media screen and (max-width: 1000px) {
  .add__container__modal__wrapper {
    width: 95%;
    margin: auto;
  }

  .add__container__modal__wrapper > section {
    flex-direction: column;
  }
  .add__container__modal__wrapper__right,
  .add__container__modal__wrapper__left {
    width: 100%;
  }
  .add__container__modal__wrapper__right {
    border-left: 0;
  }
}

@media screen and (max-width: 500px) {
  .add__container__modal {
    overflow-y: scroll;
  }

  .add__container__modal__wrapper__right__divs > span:nth-child(2) , .add__container__modal__wrapper__right__divs > span:nth-child(3) {
    flex-direction: column;
    gap: 1rem;
  }
  .add__container__modal__wrapper__right__divs > span:nth-child(2) > div , .add__container__modal__wrapper__right__divs > span:nth-child(3) > div {
    width: 95%;
    margin: 1rem auto;
  }
  .add__container__modal__wrapper__right__divs > span:nth-child(2) > div > input , .add__container__modal__wrapper__right__divs > span:nth-child(3) > div > input {
    width: 100%;
  }
}
