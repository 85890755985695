.chart__container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .3);
    direction: rtl;
}

.chart__wrapper {
    width: 900px;
    background-color: #fff;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: k;
    padding: 1rem 2rem;
}

.chart__wrapper__top {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chart__wrapper__center > span:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.chart__wrapper__center > span > input {
    border: none;
    border-bottom: 1px solid #060660;
}

.chart__wrapper__center > span:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chart__Wrapper__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

@media screen and (max-width : 950px) {
    .chart__wrapper {
        width: 95%;
        margin: auto;
    }
}

@media screen and (max-width : 650px) {
    .chart__wrapper__center > span:nth-child(2) > svg {
        width: 95%;
        margin: auto;
    }
}