.table {
    font-family: k;
    direction: rtl;
    text-align: center;
}

.users__wrapper {
    width: 100%;
    text-align: center;
}

.users__wrapper > a {
    font-family: k;
    text-align: center;
    width: 100%;
}