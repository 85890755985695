.navbar__container {
  background-color: #2c3e50;
  width: 100%;
  height: 50px;
  direction: rtl;
}

.navbar__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}

.navbar__wrapper > aside {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  transition: .8s;
}

.navbar__wrapper__aside {
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #fff;
  padding-right: 1rem;
  position: relative;
}

.navbar__wrapper__aside > svg {
    position: absolute;
    font-size: 24px;
    cursor: pointer;
    color: #000;
    left: 10px;
    top: 10px;
}

.navbar__wrapper__aside > a {
  text-decoration: none;
  font-family: k;
  font-size: 20px;
  cursor: pointer;
  color: #000;
}

.navbar__wrapper > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navbar__wrapper > div > span {
  font-family: k;
  font-size: 20px;
  color: #fff;
}

.navbar__wrapper > div > svg {
  font-size: 26px;
  cursor: pointer;
  color: #fff;
}

.editPassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #fff;
    position: fixed;
    left: 10px;
    top: -100%;
    transition: .8s;
}
.editPassword.active {
    top: 3.2rem;
}

.editPassword > p {
    font-family: k;
    font-size: 16px;
    cursor: pointer;
    color: #000;
}

.ModalBodyMe {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.ModalBodyMe > input {
    font-family: k;
    font-size: 16px;
    width: 90%;
    margin: auto;
    border: none;
    border-bottom: 1px solid #333;
    border-radius: 10px;
    outline: none;
    direction: rtl;
    padding: 8px;
}

.modal-footer {
    justify-content: center !important;
    direction: rtl;
    font-family: k;
}

@media screen and (max-width : 500px) {
  .navbar__wrapper > div > span {
    font-size: 14px;
  }
}