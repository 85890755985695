.login__container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: end;
    padding-left: 4rem;
    align-items: center;
    direction: rtl;
    background-image: url('../../../public/images/2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.login__wrapper {
    padding: 1rem;
    border-radius: 10px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #2c3e50;
    justify-content: center;
    height: fit-content;
    align-items: center;
    font-family: k;
    transition: .4s;
}

.login__wrapper:hover {
    backdrop-filter: blur(2px);
}

.login__wrapper > h3 {
    font-size: 26px;
    color: #fff;
    padding-top: 1rem;
}

.login__wrapper__username , .login__wrapper__password {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}
.login__wrapper__username > p , .login__wrapper__password > p {
    font-size: 16px;
    color: #eee;
    padding-right: 1.2rem;
}
.login__wrapper__username > svg , .login__wrapper__password > svg {
    font-size: 22px;
    position: absolute;
    right: 1.3rem;
    top: 2.2rem;
    color: #fff;
}
.login__wrapper__username > input , .login__wrapper__password > input {
    font-family: k;
    font-size: 16px;
    outline: none;
    border: none;
    width: 90%;
    margin: auto;
    padding: 5px;
    border-bottom: 2px solid #fff;
    background-color: transparent;
    color: #fff;
    padding-right: 1.8rem;
}
.login__wrapper__username > input::placeholder , .login__wrapper__password > input::placeholder {
    color: #fff;
}

.login__wrapper__password > a {
    padding-right: 1.2rem;
    font-size: 14px;
    text-decoration: none;
    padding-top: 10px;
    color: #f0f2f7;
    transition: .4s;
}
.login__wrapper__password > a:hover {
    color: blue;
}


.login__wrapper > a {
    color: #fff;
    width: 90%;
    height: 100%;
    border-bottom: 2px solid #fff;
    margin: auto;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 1rem;
    transition: .4s;
}
.login__wrapper > a:hover {
    transform: scale(1.05);
}


@media screen and (max-width : 600px) {
    .login__wrapper {
        width: 95%;
    }
    .login__container {
        justify-content: center;
        padding-left: 0;
    }
}